import { Link, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { RadioButton, Success, LoadingStyle } from '../assets/styledcomponents/StyledComponents.js';
import { useState, useEffect, useContext } from 'react';
import { CurrentUserContext } from '../security/CurrentUserContext';
import DropdownMenu from '../assets/styledcomponents/DropdownMenu.js';
import DateInput from '../assets/styledcomponents/DateInput.js';
import TableRowComponent from './TableRowComponent.jsx';
import AddUnitComponent from './AddUnitComponent.jsx';
// import { UnitDetail } from './UnitDetailComponent'
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { NavLink } from 'react-router-dom';
import ModalComponent from "./ModalComponent"
import DropdownPropType from '../assets/styledcomponents/DropdownPropType.js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function AdminComponent() {

    const { select_building } = useParams();
    let location = useLocation();
    // console.log("location",location.pathname)

    const navigate = useNavigate();
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [units, setUnits] = useState([]);
    const [origUnits, setOrigUnits] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingRowId, setEditingRowId] = useState(null);
    const [postSuccess, setPostSuccess] = useState(false);
    const [loadingState, setLoadingState] = useState(true);
    const [loadingUnits, setLoadingUnits] = useState(false);
    const [sendingPost, setSendingPost] = useState(false);
    const [buildingMeta, setBuildingMeta] = useState({});
    const [buildingMetaOrig, setBuildingMetaOrig] = useState({});
    const [editingMeta, setEditingMeta] = useState(false);
    const [addingUnit, setAddingUnit] = useState(false);  
    
    const [loadingButton, setLoadingButton] = useState(true);

    const {
        currentUserState,
        actions: {
            receiveUserInfoFromServer,
            receiveUserAuthFromServer,
            removeCurrentUser
        }
    } = useContext(CurrentUserContext);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleUnitSelect = (unit) => {
        setSelectedUnit(unit);
    };

    //get all buildings
    useEffect(() => {
        // console.log("selectedBuildingParam",select_building)
        setPostSuccess(false)
        fetch(`${API_BASE_URL}/api/allbuildings`, {
            credentials: 'include',
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('cannot get buildings');
                }
            })
            .then((response_j) => {
                setBuildings(response_j.data)
                setLoadingState(false)
            })
            .catch((error) => {
                console.log(error)
            });

    }, [select_building, postSuccess]);

    // get units
    useEffect(() => {
        setPostSuccess(false)
        // select_building
        // check db for requested building?
        
        setSelectedBuilding(select_building)

        if (selectedBuilding) {

            setLoadingUnits(true)
            let params = new URLSearchParams({
                buildingcollection: selectedBuilding,
            });
            fetch(`${API_BASE_URL}/api/buildingserver?` + params, {
                credentials: 'include'
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        alert('cannot get units in building selected');
                    }
                })
                .then((response_j) => {
                    console.log("response_j", response_j.data)
                    setUnits(response_j.data)
                    setLoadingUnits(false)
                    setOrigUnits(response_j.data)

                    // set building Meta
                    const one_unit = response_j.data[0]
                    setBuildingMeta({
                        buildingcollection: one_unit.buildingcollection,
                        building_name: one_unit.building_name,
                        address: one_unit.address,
                        proptype: one_unit.proptype
                    })
                    setBuildingMetaOrig({
                        buildingcollection: one_unit.buildingcollection,
                        building_name: one_unit.building_name,
                        address: one_unit.address,
                        proptype: one_unit.proptype,
                    })
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    }, [selectedBuilding,postSuccess]);

    const handleBuildingSelect = (buildingcollection) => {
        navigate('/admin/' + buildingcollection);
        setSelectedBuilding(buildingcollection);
    };

    const updateBuildingMeta = (event, buildingcollection, field, value) => {
        event.preventDefault();
        const newMetaData = {
         ...buildingMeta, [field]: value               
        }
        setBuildingMeta(newMetaData)
    };

    const handleDeleteUnit = (event, id,buildingCollection) => {
        event.preventDefault();
        fetch(`${API_BASE_URL}/api/delete-unit`, {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "unitId": id,
                    "buildingCollection":buildingCollection
                }),

        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            }).then(data => {                
                // setOrigUnits(units)
                setEditingRowId(null);
                setPostSuccess(true)
                // navigate("/admin")
            })
            .catch(error => console.log(error));

    };

    const handleDeleteMeta = (event, id) => {
        event.preventDefault();
        fetch(`${API_BASE_URL}/api/edit-building/` + id, {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            }).then(data => {
                // setOrigUnits(units)
                setSelectedBuilding(null)
                setEditingMeta(false);
                setPostSuccess(true)
                navigate("/admin")
            })
            .catch(error => console.log(error));

    };

    const handleSaveMeta = (event, id) => {
        event.preventDefault();

        fetch(`${API_BASE_URL}/api/edit-building`, {
            credentials: 'include',
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "formData": buildingMeta,
                }),
        })
            .then(response => {
                console.log("response.ok", response)
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            }).then(data => {
                // setOrigUnits(units)
                setEditingMeta(false);
                setPostSuccess(true)
            })
            .catch(error => console.log(error));
    };

    const toggleEditing = (event, id) => {
        event.preventDefault()
        setPostSuccess(false)
        if (editingRowId === id) {
            setEditingRowId(null);
        } else {
            setEditingRowId(id);
            setUnits(origUnits);
        }
    };

    const handleSave = (event, id) => {
        event.preventDefault();
        const formData = units.find((unit) => {
            return id === unit._id
        })

        fetch(`${API_BASE_URL}/api/update-unit`, {
            credentials: 'include',
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    "formData": formData,
                }),
        })
            .then(response => {
                console.log("response.ok", response)
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(response.statusText + " - Status: " + response.status);
                }
            }).then(data => {
                setOrigUnits(units)
                setEditingRowId(null);
                setPostSuccess(true)
            })
            .catch(error => console.log(error));
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setUnits(origUnits)
        setEditingRowId(null);
    };

    const handleInputChange = (event, id, field, value, tempList, setNewList) => {
        if (field!=="available_from"){
            event.preventDefault();
        }        
        // console.log("event, id, field, value",event, id, field, value)        
        const newData = tempList.map((item) => {
            if (item._id === id) {
                let temp_item = {}
                temp_item = { ...item, [field]: value }
                return temp_item
            } else {
                return item
            }
        });
        // console.log("newData",newData)
        setNewList(newData)
    };

    const handleAddBuilding = (submitBuilding) => {
        fetch(`${API_BASE_URL}/api/createbuilding`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(submitBuilding),
            credentials: 'include'
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    alert('request');
                }
            })
            .then((response_j) => {
                console.log('Building added successfully');
                setShowModal(false);
                setPostSuccess(true)
                setSelectedBuilding(null)
                navigate("/admin")
            })
            .catch((error) => console.log(error));

    };

    const handleLogout = () => {
        console.log('logout');
        fetch(`${API_BASE_URL}/api/logout`, {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                if (response.ok) {
                    removeCurrentUser();
                    navigate('/');
                } else {
                    throw new Error('Logout failed');
                }
            })
            .catch((error) => console.log(error));
    };

    if (!currentUserState.authenticated) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                <div>
                    <p>Please log in. Or contact administrator for Login Credentials.</p>
                    <Link to="/login">Click Here to Login</Link>
                </div>
            </div>
        );
    }

    return (
        <>
            <Layout>
                <NavBar>
                    <ToggleButton onClick={toggleSidebar} show={sidebarVisible}>
                        {sidebarVisible ? 'Hide' : 'Show'}
                    </ToggleButton>
                    <section className="w3l-header-4">
                        <div className="header" style={{ display: "flex", justifyContent: "space-between", background: "#b0bcbf" }}>
                            <Link to="/" className="brand-logo">BROWEN</Link>
                            <Nav_Items onClick={handleLogout} className="signin">Logout</Nav_Items>
                        </div>
                    </section>

                </NavBar>
                <SideBarStyled show={sidebarVisible}>
                    <Sidebar
                        buildings={buildings}
                        onBuildingSelect={handleBuildingSelect}
                        setShowModal={setShowModal}
                        onLoadingState={loadingState}
                    />
                </SideBarStyled>

                <Content sidebarVisible={sidebarVisible}>
                    {/* <SideBar show={sidebarVisible}>Fixed Side Bar</SideBar> */}
                    <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
                        {selectedBuilding ? (
                            <div>
                                {units.length > 0 ? (
                                    <Wrapper>
                                        {loadingUnits
                                            ? <Waiting><h2><LoadingStyle
                                                style={{
                                                    width: '80px',
                                                    height: '80px',
                                                    margin: '20px'
                                                }}
                                            />
                                            </h2></Waiting>
                                            :
                                            <>
                                                {/* building header */}                                            
                                                <div style={{ display: "flex", justifyContent:"space-between", alignItems: "center", minHeight:"100px"}}>
                                                    <h2>
                                                        
                                                    {
                                                    editingMeta === false ? (
                                                        <>
                                                        <div>{buildingMeta.building_name} - {buildingMeta.address}</div>
                                                        <div>{buildingMeta.proptype}</div>
                                                        </>
                                                    ) : (
                                                    <>
                                                        <div>
                                                        <label>
                                                            Building Name:
                                                            <input
                                                            type="text"
                                                            value={buildingMeta.building_name}
                                                            onChange={(event) => updateBuildingMeta(event, buildingMeta.buildingcollection, 'building_name', event.target.value)}
                                                            />
                                                        </label>
                                                        <label>
                                                            Address:
                                                            <input
                                                            // style={{ width:"50%"}}
                                                            type="text"
                                                            value={buildingMeta.address}
                                                            onChange={(event) => updateBuildingMeta(event, buildingMeta.buildingcollection, 'address', event.target.value)}
                                                            />
                                                        </label>
                                                        
                                                        </div>
                                                        <div>
                                                            <label>
                                                        Property Type:
                                                        <DropdownPropType
                                                            options={[
                                                                {label: "rent",value: "rent"},
                                                                {label: "sale",value: "sale"},
                                                                {label: "house",value: "house"},
                                                                {label: "commercial",value: "commercial"},
                                                            ]}
                                                            onSelect={(event) =>updateBuildingMeta(event, buildingMeta.buildingcollection, "proptype", event.target.value)}
                                                            value={buildingMeta.proptype}
                                                            isDisabled={false}                                                           
                                                        />                                                            
                                                        </label>
                                                        </div>
                                                        </>
                                                    )
                                                    }
                                                    </h2>

                                                    {editingMeta===true ?
                                                    <div style={{ display: "flex",  flexDirection: "column"}}>
                                                        <Button type="submit" onClick={(event) => handleSaveMeta(event, buildingMeta._id)}>Save</Button>
                                                        {/* <Button type="submit" disabled={loadingButton}>
                                                            {loadingButton ? (<LoadingStyle style={{width: '20px',height: '20px'}}/>) : (<>Save</>)}
                                                        </Button> */}
                                                        <Button onClick={()=>{setBuildingMeta(buildingMetaOrig); setEditingMeta(!editingMeta)}}>Cancel</Button>
                                                        <Button onClick={(event) => handleDeleteMeta(event, buildingMeta.buildingcollection)}>Delete</Button>
                                                    </div>
                                                    :
                                                    <Button onClick={() => setEditingMeta(!editingMeta)}>Edit</Button>
                                                    }                                                    
                                                    </div>
                                    
                                                {postSuccess
                                                    && <SuccessAlert>Success!</SuccessAlert>
                                                }

                                                {/* building units */}
                                                <Container>
                                                    <Item>
                                                        {/* <Label>ID</Label> */}
                                                        <Label>Unit Name</Label>
                                                        <Label>Model Type</Label>
                                                        <Label>Floor Number</Label>
                                                        <Label>Monthly Rent</Label>
                                                        <Label>Square Feet</Label>
                                                        <Label>Bedrooms</Label>
                                                        <Label>Baths</Label>
                                                        <Label>Availability</Label>
                                                        <Label>Available From</Label>
                                                        <Label>Floorplan URL</Label>
                                                        <Label>Actions</Label>
                                                        {/* <Label></Label> */}

                                                    </Item>
                                                    {units.map((item, count) => {
                                                        return (
                                                            <>
                                                            <TableRowComponent 
                                                            item={item}
                                                            editingRowId ={editingRowId}
                                                            handleInputChange={handleInputChange}
                                                            handleSave={handleSave} 
                                                            handleCancel={handleCancel}
                                                            handleDeleteUnit={handleDeleteUnit}
                                                            toggleEditing={toggleEditing}
                                                            units={units}
                                                            setUnits={setUnits}
                                                            />
                                                            </>                                                            
                                                        )
                                                    })}
                                                {addingUnit &&(
                                                <>
                                                
                                                <AddUnitComponent selectedBuilding={selectedBuilding} setPostSuccess={setPostSuccess} setAddingUnit={setAddingUnit} addingUnit={addingUnit}/>
                                                
                                                </>
                                                )}
                                                </Container>
                                            </>

                                        }
                                        <Button style = {{background: '#4CAF50'}} onClick={()=>setAddingUnit(!addingUnit)}>Add Unit</Button>
                                    </Wrapper>
                                    
                                ) : (
                                    <p>Building does not exist</p>
                                )}                                
                            </div>
                        ) : (
                            <h2>Select a building to view details</h2>
                        )}
                    </div>
                    
                    <ModalComponent isOpen={showModal} onSubmit={handleAddBuilding} 
                        setShowModal={setShowModal}
                        setPostSuccess={setPostSuccess}
                        setSelectedBuilding={setSelectedBuilding}                        
                        />
                </Content>
            </Layout>

        </>
    )
}

// Sidebar Component
const Sidebar = ({ buildings, onBuildingSelect, setShowModal, onLoadingState }) => {
    return (
        <>
            <h2 style={{marginBottom: '10px'}}>Buildings</h2>
            <>
                {onLoadingState ? (
                    <LoadingStyle
                        style={{
                            width: '80px',
                            height: '80px',
                            margin: '20px'
                        }}
                    />
                ) : (
                    <>
                        <ul>
                            {buildings.map((building) => (
                                <li key={building._id} style={styles.sidebarItem}>
                                    <button onClick={() => onBuildingSelect(building.buildingcollection)} style={styles.button}>
                                        {building.building_name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <button onClick={() => setShowModal(true)} style={styles.addButton}>Add Building</button>
                    </>
                )}
            </>

        </>
    );
};

const styles = {
    sidebar: {
        width: '250px',
        background: '#f4f4f4',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    sidebarItem: {
        marginBottom: '10px',        
    },
    button: {
        width: '100%',
        padding: '10px',
        border: 'none',
        borderRadius: '5px',
        // background: 'white',
        color: 'black',
        cursor: 'pointer',
    },
    addButton: {
        width: '100%',
        padding: '10px',
        marginTop: '20px',
        background: '#4CAF50',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        fontSize: '20px',
        paddingLeft: '10px',
        paddingRight: '10px',
        // background: '#ef5350',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        marginBottom: '1px',
    }
};

const Nav_Items = styled(NavLink)`
    text-decoration: none;
    transition: 100ms;
    color: white;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    margin: 5px;
    font-size: 20px;
    width: 125px;
    text-align: center;
    border-radius: 3px;
    border: 3px solid transparent;
    &:hover {
        border: 3px solid transparent;
        color: grey;
    }

    &:active {
        color: white;
        border: 3px solid transparent;
        background-color: var(--primary-color);
    }
    .active {
        color: var(--primary-color);
    }
    &:disabled,
    &:hover:disabled {
        background-color: #707070;
        opacity: 0.4;
        cursor: not-allowed;
    }
    @media (max-width: 750px) {
        /* width: 50px; */
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }
`;

const SuccessAlert = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-size: 36px;
font-family: var(--font-heading);
background-color: #dff0d8;
border: 1px solid #d6e9c6;
border-radius: 4px;
color: #3c763d;
padding: 15px;
margin-left: 20px;
margin-right: 20px;
`;

const Form_Select = styled.select`
width: 100px;
flex: 1;
margin-right: 10px;
font-family: var(--font-body);
font-size: 16px;
height: 42px;
border: 2px solid var(--color-orange);
border-radius: 4px;
padding: 0 12px;
padding-top: 2px;
`;

const Label = styled.div`
width: 100px;
font-weight: bold;
flex: 1;
margin-right: 10px;
/* padding: 0 12px; */
font-family: var(--font-body);
min-width: 100px;
`;

const Value = styled.input`
width: 100px;
flex: 1;
margin-right: 10px;
/* border:none; */
`;
const Value_id = styled.div`
/* min-width: 125px; */
width: 100px;
flex: 1;
margin-right: 10px;
overflow-wrap: anywhere;
font-family: var(--font-body);
font-size: 16px;
height: 42px;
border: 2px solid var(--color-orange);
border-radius: 4px;
padding: 0 12px;
padding-top: 2px;
`;

const Container = styled.form`
display: grid;
grid-auto-rows: 110px;
/* gap: 10px; */
padding: 20px;
/* margin-top: 50px; */
/* border-radius: 50%; */
`;
const Item = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
background-color: #f5f5f5;
padding: 10px;
border-bottom: 1px solid black;
`;

const Button = styled.button`
font-size:22px;
padding-left: 10px;
padding-right: 10px;
background-color: #ef5350;
color: #fff;
border: none;
border-radius: 5px;
margin-bottom: 1px;
cursor: pointer;
&:hover {
background-color: #e53935;
}
`;

const Wrapper = styled.div`
margin-top: 20px;
`;

const Waiting = styled.div`
font-size: 36px;
justify-content: center;
font-weight: bold;
align-items: center;
position: relative;
display: flex;
justify-content: flex-start;
flex-direction: column;
height: calc(100vh - 175px);
width: 100%;
white-space: nowrap;
color:black;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const NavBar = styled.div`
text-align: center;
position: fixed;
width: 100%;
top: 0;
left: 0;
z-index: 1000;
background: #b0bcbf;
padding: 10px 20px;
`;

const SideBarStyled = styled.div`
  background-color: #b0bcbf;
  color: white;
  width: 200px;
  padding: 20px;
  position: fixed;
  top: 62px; /* Adjust based on the height of your NavBar */
  left: 0;
  bottom: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(-100%)')};
`;

const Content = styled.div`
  margin-left: ${({ sidebarVisible }) => (sidebarVisible ? '200px' : '0')};
  margin-top: 60px;
  padding: 20px;
  overflow-y: scroll;
  height: calc(100vh - 60px); /* Adjust based on the height of your NavBar */
  transition: margin-left 0.3s ease-in-out;
`;

const ToggleButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? '200px' : '0')};
    top: 65px;
    z-index: 1010;

    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    // background-color: #FFDB58;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
        
    transition: left 0.3s ease-in-out;

    &:hover {
        background-color: #e53935;
    }
`;