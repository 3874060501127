import React from "react";
import styled from "styled-components";
import { AiFillCheckCircle } from "react-icons/ai";
import { ImSpinner } from "react-icons/im";

const RadioWrapper = styled.div`
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
    &:hover {
    }
`;

const RadioLabel = styled.label`
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 16px;
    user-select: none;
`;

const RadioInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ span:after {
        display: block;
    }
`;

const RadioCheckmark = styled.span`
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #333;

    &:after {
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #333;
    }
`;

export const RadioButton = ({ label, ...props }) => (
    <RadioWrapper>
        <RadioLabel>
            <RadioInput type="radio" {...props} />
            <RadioCheckmark />
            {label}
        </RadioLabel>
    </RadioWrapper>
);


const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SuccessIcon = styled.i`
  font-size: 36px;
  color: green;
`;

const SuccessMessage = styled.p`
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  color: green;
`;

export const Success = ({ message }) => (
  <SuccessWrapper>
    <SuccessIcon><AiFillCheckCircle/></SuccessIcon>
    <SuccessMessage>{message}</SuccessMessage>
  </SuccessWrapper>
);

export const LoadingStyle = styled(ImSpinner)` 
    /* width: ${props => props.size || '50px'}; */
    /* height: ${props => props.size || '50px'}; */
    width: 50px;
    height: 50px;
    object-fit: cover;
    color:black;
    border-radius: 50%;
    animation: rotation 2s infinite linear;
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
`;

