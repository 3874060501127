import { createContext, useState, useReducer } from "react";

export const CurrentUserContext = createContext();

const initialState = {
    "_id": "",
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone": "",
    "date_created": "", 
    "password": "",
    "deleted": false,
    "authenticated":false,
}

const reducer = (currentUserState, action) => {
    if (action.type === "receive-user-info-from-server"){
        // console.log("info context", action)
        // console.log(Object.keys(action))
        return {
            ...currentUserState,
            _id: action._id,
            first_name: action.first_name,
            last_name: action.last_name,
            email: action.email,
            phone: action.phone,
            date_created: action.date_created, 
            password: action.password,
            deleted: action.deleted,
            authenticated:false,
        }
    }
    if (action.type === "receive-user-auth-from-server"){
        // console.log("auth context", action)
        return {
            ...currentUserState,
            "authenticated":action.authenticated,
        }
    }
    if (action.type === "remove-current-user"){
        // console.log("auth context", action)
        return initialState
    }
    else{
        throw new Error ("Unrecognized action:"+action.type)
    }
}

export const CurrentUserProvider = ({ children }) => {
    const [currentUserState, dispatch] = useReducer(reducer, initialState);
    
    const receiveUserInfoFromServer = (data) => {
        // console.log("data info",data)
    dispatch({
        type: "receive-user-info-from-server",
        ...data,
    });
    };

    const receiveUserAuthFromServer = (data) => {
        // console.log("data auth",data)
        dispatch({
            type: "receive-user-auth-from-server",
            ...data,
        });
        };
    const removeCurrentUser = (data) => {
        // console.log("data remove",data)
        dispatch({
            type: "remove-current-user",
            ...data,
        });
        };
return (
<CurrentUserContext.Provider
    value={{
        currentUserState,
        actions: {
        receiveUserInfoFromServer,
        receiveUserAuthFromServer,
        removeCurrentUser,
        },
    }}
>
    {children}
</CurrentUserContext.Provider>
);
};